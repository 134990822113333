const BgCircle = ({ width, height, style, mode = "dark" }) => {
  if (mode === "dark") {
    return (
      <div
        className="bg-circle"
        style={{
          ...style,
          width,
          height,
          background: "#da1f26b0",
          filter: " blur(30px)",
        }}
      ></div>
    );
  } else {
    return (
      <div
        className="bg-circle absolute"
        style={{
          ...style,
          width,
          position: "absolute",
          height,
          // background: "#da1f26",
          background: "#1e5799",
          filter: " blur(25px)",
        }}
      ></div>
    );
  }
};

export default BgCircle;
