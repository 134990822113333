import React, { useState } from "react";
import useServiceContent from "../../_data/ServiceContent";
import { CircularProgress } from "@mui/material";
import { useStateContext } from "../../context/ContextProvider";
import { LuSend } from "react-icons/lu";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ContactUsForm = () => {
  const { t } = useStateContext();

  
  const ServiceContent = useServiceContent();

  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedServices, setSelectedServices] = useState(() => {
    const initialSelectedServices = {};
    ServiceContent.forEach((service) => {
      initialSelectedServices[service.id] = false;
    });
    return initialSelectedServices;
  });
  const [submitted, setSubmitted] = useState(false);

  const handleCheckboxChange = (serviceId) => {
    setSelectedServices((prevSelected) => ({
      ...prevSelected,
      [serviceId]: !prevSelected[serviceId],
    }));
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleContactChange = (e) => {
    setContact(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // GET URL AND DEVICE AGENT
    let source = "Website";
    const userAgent = navigator.userAgent;
    const pageURL = window.location.href;
    if (pageURL.includes("?")) {
      const trackId = pageURL.split("?")[1];
      if (trackId.startsWith("gclid")) {
        source = "GoogleAds";
      } else if (trackId.startsWith("ttclid")) {
        source = "TikTok";
      } else if (trackId.startsWith("ScCid")) {
        source = "Snapchat";
      } else if (trackId.startsWith("fbclid")) {
        source = "Facebook";
      } else if (trackId.startsWith("twclid")) {
        source = "Twitter";
      } else {
        source = "Organic";
      }
    }

    const selectedServiceNames = ServiceContent.filter(
      (service) => selectedServices[service.id]
    ).map((service) => service.title);

    try {
      const data = {
        email: process.env.REACT_APP_RECIPIENT,
        notification: "common",
        title: "New Enquiry from hikaltech.com",
        message: `<p>Name: ${name}</p><p>Contact Number: ${contact}</p><p>Email address: ${email}</p><p>Services: ${selectedServiceNames}</p><p>Note: ${message}</p><br /><p>Source: ${source}</p><p>Link: ${pageURL}</p><p>User's Device: ${userAgent}</p>`,
        style: "span{font-weight: bold; color: #1245A8;}",
      };
      await fetch("https://staging.hikalcrm.com/api/newEmail", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });

      setSubmitted(true);
    } catch (error) {
      console.log("Failed to send email", error);
      toast.error("Something went wrong", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setLoading(false);

    // Reset the form fields
    setName("");
    setContact("");
    setEmail("");
    setMessage("");
    setSelectedServices((prevSelected) => {
      const resetSelectedServices = {};
      ServiceContent.forEach((service) => {
        resetSelectedServices[service.id] = false;
      });
      return resetSelectedServices;
    });
  };
  return (
    <div className="mt-10 w-full bg-white p-5 ">
      {submitted ? (
        <div className="flex mt-12 flex-col items-center justify-center">
          <img width={100} src="/assets/message_sent.png" alt="" />
          <h1>{t("message_sent_successfully")}</h1>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="contact-form w-full flex flex-col gap-5"
        >
          <div>
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-semibold uppercase text-gray-900"
            >
              {t("name")}
            </label>
            <input
              type="text"
              id="name"
              onChange={handleNameChange}
              className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm"
              placeholder={t("your_good_name")}
              required
            />
          </div>

          <div>
            <label
              htmlFor="contact"
              className="block mb-2 text-sm font-semibold uppercase text-gray-900"
            >
              {t("contact_number")}
            </label>
            <input
              type="tel"
              id="contact"
              onChange={handleContactChange}
              className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm"
              placeholder="+971567890123"
              required
            />
          </div>

          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-semibold uppercase text-gray-900"
            >
              {t("email_address")}
            </label>
            <input
              type="email"
              id="email"
              onChange={handleEmailChange}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
              placeholder="example@gmail.com"
              required
            />
          </div>

          <div>
            <label
              htmlFor="services"
              className="block mb-2 text-sm font-semibold uppercase text-gray-900"
            >
              {t("services")}
            </label>
            {ServiceContent.map((service) => (
              <div key={service.id} className="flex items-center">
                <input
                  type="checkbox"
                  id={service.id}
                  checked={selectedServices[service.id]}
                  onChange={() => handleCheckboxChange(service.id)}
                  className="mx-2"
                />
                <label
                  htmlFor={service.id}
                  className="text-sm text-gray-900 leading-6"
                >
                  {service.title}
                </label>
              </div>
            ))}
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-semibold uppercase text-gray-900"
            >
              {t("message")}
            </label>
            <textarea
              id="message"
              rows="3"
              onChange={handleMessageChange}
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 "
              placeholder={t("leave_a_note")}
            ></textarea>
          </div>
          <button
            type="submit"
            className="py-3 px-5 flex items-center justify-center text-sm font-semibold text-center text-white rounded-md bg-primary sm:w-fit"
          >
            <p className="mx-2">{t("send_message")}</p>{" "}
            {loading ? (
              <CircularProgress size={15} style={{ color: "white" }} />
            ) : (
              <LuSend style={{ color: "white" }} size={18} />
            )}
          </button>
        </form>
      )}
    </div>
  );
};

export default ContactUsForm;
