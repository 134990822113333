import { useStateContext } from "../../context/ContextProvider";
import BgCircle from "../bgCircle";
import BgDots from "../bgDots";

const SectionHeading = ({ caption, text, darkMode, bold }) => {
  const { i18n, isLangRTL } = useStateContext();

  return (
    <div
      className={`flex ${
        darkMode ? "text-white" : ""
      } pt-16 pb-6 relative items-center`}
    >
      <BgDots
        grid={{ x: 3, y: 2 }}
        style={{
          position: "relative",
          marginRight: !isLangRTL(i18n.language) && 20,
          marginLeft: isLangRTL(i18n.language) && 20,
        }}
      />
      <div className="relative w-max">
        <small
          className={`text-sm uppercase ${bold ? "font-black" : "font-bold"}`}
        >
          {caption}
        </small>
        <h1 className="text-2xl font-bold text-[var(--primary-color)]">
          {text}
        </h1>
      </div>

      <BgCircle
        mode="light"
        style={{
          left: !isLangRTL(i18n.language) && -15,
          right: isLangRTL(i18n.language) && -15,
          top: 50,
        }}
        width={70}
        height={50}
      />
    </div>
  );
};

export default SectionHeading;
