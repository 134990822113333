import { useStateContext } from "../../context/ContextProvider";
import Hero from "../../components/hero/Hero";
import Team from "../../assets/vector/team-vector.png";
import { motion, useScroll } from "framer-motion";
import WhyJoinUs from "../../components/careers-hirings/WhyJoinUs";
import BgDots from "../../components/bgDots";
import {
    BsCheckCircleFill
} from "react-icons/bs";
import BgCircle from "../../components/bgCircle";

const CareersHirings = () => {
    const {
        t,
        i18n,
        isLangRTL
    } = useStateContext();

    const { scrollYProgress } = useScroll();

    const isMobile = window.innerWidth <= 800;

    return (
        <>
            <div className={`relative py-10 w-full`} style={{ width: "100vw" }}>
                <BgDots style={{
                    left: !isLangRTL(i18n.language) && "7%",
                    right: isLangRTL(i18n.language) && "7%",
                    top: "15vh",
                    opacity: "0.7",
                    zIndex: -1,
                    position: "absolute"
                }} />
                <BgDots style={{
                    right: !isLangRTL(i18n.language) && "7%",
                    left: isLangRTL(i18n.language) && "7%",
                    bottom: "0",
                    position: "absolute",
                    zIndex: -1,
                    opacity: "0.9"
                }} />
                <BgCircle
                    width={170}
                    height={170}
                    style={{
                        top: "24%",
                        left: !isLangRTL(i18n.language) && "40%",
                        right: isLangRTL(i18n.language) && "40%",
                        zIndex: "-1",
                        position: "absolute",
                        opacity: 0.7,
                    }}
                />

                <div className="flex flex-col gap-4 items-center justify-center mt-5 pt-5 pb-0">
                    <div className={`flex  mt-5 gap-4 items-center justify-center ${isMobile ? "flex-col" : "flex-row"}`}>
                        <h1 className="text-white text-3xl font-bold leading-tight">
                            {t("careers_heading_white")}
                        </h1>
                        <h1 className="text-3xl font-bold leading-tight uppercase text-[var(--primary-color)]">
                            {t("careers_heading_red")}
                        </h1>
                    </div>
                    <div className="flex items-center text-gray-200 font-light">
                        <BsCheckCircleFill size={16} color="red" />
                        <p className={isLangRTL(i18n.language) ? "mr-2" : "ml-2"}>
                            {t("careers_sub_heading")}
                        </p>
                    </div>
                    <div className="flex flex-col items-center justify-center text-gray-200 font-light mt-5">
                        <p className="leading-7">
                            {t("careers_question")}
                        </p>
                        <p className="leading-7">
                            {t("careers_answer")}
                        </p>
                    </div>
                </div>
            </div>

            <div
                className={`mx-auto pb-0 pt-10 ${isMobile ? "p-2" : "p-10"}`}
                style={{
                    background: "linear-gradient(to bottom, black, var(--primary-color), var(--primary-color)",
                }}
            >
                <img src={Team} alt="Hikal Tech" />
            </div>

            <motion.div className="products-separator origin-[50%]" style={{ scaleX: scrollYProgress }}></motion.div>

            <WhyJoinUs />
        </>
    );
};

export default CareersHirings;
