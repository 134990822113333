import Hero from "../../components/hero/Hero";
import OurTeam from "../../components/about/OurTeam";
import OurClients from "../../components/about/OurClients";
import { useStateContext } from "../../context/ContextProvider";
import SectionHeading from "../../components/sectionHeading/SectionHeading";
import ParticleBackground from "../../components/ParticleBG";

const AboutPage = () => {
  const { t } = useStateContext();

  const aboutContent = [
    {
      title: t("our_mission"),
      subtitle: t("mission_subtitle"),
      content: t("mission_content"),
    },
    {
      title: t("what_we_do"),
      subtitle: t("do_subtitle"),
      content: t("do_content"),
    },
    {
      title: t("choose_us"),
      subtitle: t("choose_subtitle"),
      content: t("choose_content"),
    },
  ];

  return (
    <>
      <ParticleBackground />

      <Hero
        page="About"
        title={t("who_we_are")}
        subtitle={t("innovators_in_tech")}
      />

      <div id="about-section" className="mb-10 mt-60 ">
        <div className="px-8 mb-6">
          <p className="font-light text-white opacity-50 leading-6">
            {t("about_company")}
          </p>
        </div>
        {/* <OurTeam /> */}
        <div className="w-[80%] mx-auto">
          {aboutContent?.map((content) => (
            <>
              <div className="mb-7">
                <SectionHeading
                  darkMode={true}
                  text={content?.title}
                  caption={content?.subtitle}
                />
                <p className="font-light text-white opacity-50 leading-6 mt-3">
                  {content?.content}
                </p>
              </div>
            </>
          ))}
          <div className=" py-5 ">
            <div className="mx-auto">
              <SectionHeading text={t("our_clients")} />
            </div>
            <OurClients />
          </div>
        </div>
      </div>

      {/* <div className=" py-5 px-[7%]">
        <div className="mx-auto">
          <SectionHeading text={t("our_clients")} />
        </div>
        <OurClients />
      </div> */}

      {/* <div className="mt-[120px] mb-[40px]">
      <h1 className="text-center text-primary font-bold text-3xl">Our Team</h1>
        <div className="flex mt-[120px] justify-center mx-auto w-[80%]">
            <div className="our-team-grid grid gap-y-24 grid-cols-1 md:grid-cols-2 w-full">
            <div className="relative flex">
                <div
                  className="w-[200px] rounded-md shadow shadow-red-300 h-[300px]"
                  style={{
                    backgroundImage: "url('/team/rimsha.jpg')",
                    backgroundRepeat: "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
                <div className="absolute pt-8 h-full left-[230px]">
                  <BgDots
                    grid={{ x: 3, y: 3 }}
                    style={{ opacity: "0.4", left: 0, zIndex: -5 }}
                  />
                  <BgCircle
                    width={60}
                    height={60}
                    style={{
                      top: "0%",
                      position: "absolute",
                      left: "40px",
                      zIndex: "-5",
                    }}
                  />
                  <h1 className="text-white absolute w-max left-[70px] top-8 m-0 font-black text-3xl">
                    Rimsha Sehar
                  </h1>
                  <div className="h-full w-max flex team-info flex-col justify-end">
                    <div className="flex pb-8 h-full team-info-separator items-end">
                      <div className="w-[1px] opacity-[0.45] bg-primary h-[50%]"></div>
                    </div>
                    <div>
                      <p className="text-white text-sm mt-1">
                        Operations Manager
                      </p>
                    </div>
                  </div>
                </div>
              </div>
                <div className="relative flex">
                <div
                  className="w-[200px] rounded-md shadow shadow-red-300 h-[300px]"
                  style={{
                    backgroundImage: "url('/team/muskan.jpg')",
                    backgroundRepeat: "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
                <div className="absolute pt-8 h-full left-[230px]">
                  <BgDots
                    grid={{ x: 3, y: 3 }}
                    style={{ opacity: "0.4", left: 0, zIndex: -5 }}
                  />
                  <BgCircle
                    width={60}
                    height={60}
                    style={{
                      top: "0%",
                      position: "absolute",
                      left: "40px",
                      zIndex: "-5",
                    }}
                  />
                  <h1 className="text-white absolute w-max left-[70px] top-8 m-0 font-black text-3xl">
                    Muskan <br/> Bucharacharya
                  </h1>
                  <div className="h-full w-max flex team-info flex-col justify-end">
                    <div className="flex pb-8 h-full team-info-separator items-end">
                      <div className="w-[1px] opacity-[0.45] bg-primary h-[50%]"></div>
                    </div>
                    <div>
                      <p className="text-white text-sm mt-1">
                        Chief Technology Officer (CTO)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative flex">
                <div
                  className="w-[200px] rounded-md shadow shadow-red-300 h-[300px]"
                  style={{
                    backgroundImage: "url('/team/ubaid.jpg')",
                    backgroundRepeat: "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
                <div className="absolute pt-8 h-full left-[230px]">
                  <BgDots
                    grid={{ x: 3, y: 3 }}
                    style={{ opacity: "0.4", left: 0, zIndex: -5 }}
                  />
                  <BgCircle
                    width={60}
                    height={60}
                    style={{
                      top: "0%",
                      position: "absolute",
                      left: "40px",
                      zIndex: "-5",
                    }}
                  />
                  <h1 className="text-white absolute w-max left-[70px] top-8 m-0 font-black text-3xl">
                    Ubaid Ur <br /> Rehman
                  </h1>
                  <div className="h-full w-max flex team-info flex-col justify-end">
                    <div className="flex pb-8 h-full team-info-separator items-end">
                      <div className="w-[1px] opacity-[0.45] bg-primary h-[50%]"></div>
                    </div>
                    <div>
                      <div className="flex space-x-1 items-center">
                        <IoLogoJavascript style={{ color: "white" }} size={18} />
                        <FaReact style={{ color: "white" }} size={18} />
                      </div>
                      <p className="text-white text-sm mt-1">
                        Frontend Developer I
                      </p>
                    </div>
                  </div>
                </div>
              </div>
           <div className="relative flex">
                <div
                  className="w-[200px] rounded-md shadow shadow-red-300 h-[300px]"
                  style={{
                    backgroundImage: "url('/team/junaid.jpg')",
                    backgroundRepeat: "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
                <div className="absolute pt-8 h-full left-[230px]">
                  <BgDots
                    grid={{ x: 3, y: 3 }}
                    style={{ opacity: "0.4", left: 0, zIndex: -5 }}
                  />
                  <BgCircle
                    width={60}
                    height={60}
                    style={{
                      top: "0%",
                      position: "absolute",
                      left: "40px",
                      zIndex: "-5",
                    }}
                  />
                  <h1 className="text-white absolute w-max left-[70px] top-8 m-0 font-black text-3xl">
                    Muhammad <br/>Junaid
                  </h1>
                  <div className="h-full w-max flex team-info flex-col justify-end">
                    <div className="flex pb-8 h-full team-info-separator items-end">
                      <div className="w-[1px] opacity-[0.45] bg-primary h-[50%]"></div>
                    </div>
                    <div>
                      <div className="flex space-x-1 items-center">
                        <IoLogoJavascript style={{ color: "white" }} size={18} />
                        <FaReact style={{ color: "white" }} size={18} />
                      </div>
                      <p className="text-white text-sm mt-1">
                        Frontend Developer II
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative flex">
                <div
                  className="w-[200px] rounded-md shadow shadow-red-300 h-[300px]"
                  style={{
                    backgroundImage: "url('/team/qasim.jpg')",
                    backgroundRepeat: "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
                <div className="absolute pt-8 h-full left-[230px]">
                  <BgDots
                    grid={{ x: 3, y: 3 }}
                    style={{ opacity: "0.4", left: 0, zIndex: -5 }}
                  />
                  <BgCircle
                    width={60}
                    height={60}
                    style={{
                      top: "0%",
                      position: "absolute",
                      left: "40px",
                      zIndex: "-5",
                    }}
                  />
                  <h1 className="text-white absolute left-[70px] top-8 m-0 font-black text-3xl">
                    Qasim Ali
                  </h1>
                  <div className="h-full w-max flex team-info flex-col justify-end">
                    <div className="flex pb-8 h-full team-info-separator items-end">
                      <div className="w-[1px] opacity-[0.45] bg-primary h-[50%]"></div>
                    </div>
                    <p className="text-white text-sm">
                      Quality Assurance I (QA)
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative flex">
                <div
                  className="w-[200px] rounded-md shadow shadow-red-300 h-[300px]"
                  style={{
                    backgroundImage: "url('/team/zaki.jpg')",
                    backgroundRepeat: "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
                <div className="absolute pt-8 h-full left-[230px]">
                  <BgDots
                    grid={{ x: 3, y: 3 }}
                    style={{ opacity: "0.4", left: 0, zIndex: -5 }}
                  />
                  <BgCircle
                    width={60}
                    height={60}
                    style={{
                      top: "0%",
                      position: "absolute",
                      left: "40px",
                      zIndex: "-5",
                    }}
                  />
                  <h1 className="text-white absolute left-[70px] top-8 m-0 font-black text-3xl">
                    Syed Zaki
                  </h1>
                  <div className="h-full w-max flex team-info flex-col justify-end">
                    <div className="flex pb-8 h-full team-info-separator items-end">
                      <div className="w-[1px] opacity-[0.45] bg-primary h-[50%]"></div>
                    </div>
                    <p className="text-white text-sm">
                      Quality Assurance II (QA)
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative flex">
                <div
                  className="w-[200px] rounded-md shadow shadow-red-300 h-[300px]"
                  style={{
                    backgroundImage: "url('/team/usama.jpg')",
                    backgroundRepeat: "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
                <div className="absolute pt-8 h-full left-[230px]">
                  <BgDots
                    grid={{ x: 3, y: 3 }}
                    style={{ opacity: "0.4", left: 0, zIndex: -5 }}
                  />
                  <BgCircle
                    width={60}
                    height={60}
                    style={{
                      top: "0%",
                      position: "absolute",
                      left: "40px",
                      zIndex: "-5",
                    }}
                  />
                  <h1 className="text-white absolute left-[70px] top-8 m-0 font-black text-3xl">
                    Usama Ramzan
                  </h1>
                  <div className="h-full w-max flex team-info flex-col justify-end">
                    <div className="flex pb-8 h-full team-info-separator items-end">
                      <div className="w-[1px] opacity-[0.45] bg-primary h-[50%]"></div>
                    </div>
                    <div>
                      <div className="flex space-x-1 items-center">
                        <BiLogoPhp style={{ color: "white" }} size={18} />
                        <FaLaravel style={{ color: "white" }} size={18} />
                      </div>
                      <p className="text-white text-sm mt-1">
                        Backend Developer
                      </p>
                    </div>
                  </div>
                </div>
              </div>
                <div className="relative flex">
                <div
                  className="w-[200px] rounded-md shadow shadow-red-300 h-[300px]"
                  style={{
                    backgroundImage: "url('/team/hammad.jpg')",
                    backgroundRepeat: "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
                <div className="absolute pt-8 h-full left-[230px]">
                  <BgDots
                    grid={{ x: 3, y: 3 }}
                    style={{ opacity: "0.4", left: 0, zIndex: -5 }}
                  />
                  <BgCircle
                    width={60}
                    height={60}
                    style={{
                      top: "0%",
                      position: "absolute",
                      left: "40px",
                      zIndex: "-5",
                    }}
                  />
                  <h1 className="text-white absolute left-[70px] top-8 m-0 font-black text-3xl">
                    Hammad Sarwar
                  </h1>
                  <div className="h-full w-max flex team-info flex-col justify-end">
                    <div className="flex pb-8 h-full team-info-separator items-end">
                      <div className="w-[1px] opacity-[0.45] bg-primary h-[50%]"></div>
                    </div>
                    <div>
                      <div className="flex space-x-1 items-center">
                        <RiFlutterFill style={{ color: "white" }} size={18} />
                        <FaPython style={{ color: "white" }} size={18} />
                      </div>
                      <p className="text-white text-sm mt-1">
                        Mobile App Developer
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div> */}
    </>
  );
};

export default AboutPage;
