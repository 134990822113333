import React, { useState, useEffect } from 'react';
import { BsArrowUpCircleFill } from 'react-icons/bs';
import { useStateContext } from '../context/ContextProvider';

const ScrollToTopButton = () => {
  const {
    t,
    i18n,
    isLangRTL
  } = useStateContext();

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    setIsVisible(scrollTop > 100); // Adjust the scroll threshold as needed
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  return (
    <div 
      className={`fixed bottom-[15px] p-1.5 bg-white text-[var(--primary-color)] rounded-full shadow-md cursor-pointer transition-opacity duration-300 flex items-center ${
        isVisible ? 'opacity-100' : 'opacity-0'
      } ${
        isLangRTL(i18n.language) ? "left-[7%]" : "right-[7%]"
      }`}
      style={{
        zIndex: 9,
      }}
      onMouseOver={handleHover}
      onMouseOut={handleLeave}
      onClick={scrollToTop}
    >
      <BsArrowUpCircleFill size={40} style={{ zIndex: 10 }}  />
      {isHovered && (
        <div
          className={`absolute text-[var(--primary-color)] flex bg-white font-bold p-2 px-4 rounded-lg bottom-[7%] ${
            isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
          }`}
          style={{
            right: !isLangRTL(i18n.language) && "calc(7% + 35px)",
            left: isLangRTL(i18n.language) && "calc(7% + 35px)",
          }}
        >
          {t("top")}
        </div>
      )}
    </div>
  );
};

export default ScrollToTopButton;
