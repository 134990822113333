import Hero from "../../components/hero/Hero";
import Products from "../../components/home/Products";
import ParticleBackground from "../../components/ParticleBG";
import { useStateContext } from "../../context/ContextProvider";

const ProductsPage = () => {
  const { t } = useStateContext();
  return (
    <div>
      <ParticleBackground />
      <Hero
        page="Products"
        title={t("our_products")}
        subtitle={t("products_caption")}
      />
      <div className="mb-24">
        <Products />
      </div>
    </div>
  );
};

export default ProductsPage;
