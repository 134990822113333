import React from "react";
import { Link } from "react-router-dom";
import whiteLogo from "../../assets/whiteLogo.png";
import { useStateContext } from "../../context/ContextProvider";
import {
  BiGlobe,
  BiLogoFacebook,
  BiLogoInstagram,
  BiLogoLinkedin,
  BiLogoTiktok,
  BiPhone,
  BiLogoGmail,
  BiMapPin,
} from "react-icons/bi";

const Footer = () => {
  const newsletterButton = () => {
    // Functionality for the newsletter subscription button
  };
  const { t, i18n, isLangRTL } = useStateContext();

  return (
    // <div className="bg-[#000000] py-5 h-full flex items-center justify-center text-center text-white">
    <div className="banner-container text-center text-white w-full pt-5">
      {/* LOGO AND LINKS */}
      <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-3 pt-5 px-5">
        {/* LOGO  */}
        <div
          className="col-span-1 flex items-center p-5"
          style={{
            justifyContent: "flex-start",
            alignContent: "space-between",
            flexDirection: "column",
            zIndex: "1",
          }}
        >
          <img
            src={whiteLogo}
            style={{ width: "200px" }}
            alt="Hikal Tech"
            className="m-0 p-1"
          />
          <div className="h-0.5 w-full my-3 bg-[#DA1F26]"></div>
          <p className="text-sm leading-7">{t("footer_caption")}</p>
          {/* <div className="h-0.5 w-full my-3 bg-[#DA1F26]" ></div> */}
          {/* SOCIAL ICONS  */}
          <div className="grid grid-cols-5 gap-3 place-content-center items-center pt-3 w-[50%] mt-3 mx-auto">
            <a
              href="https://hikaltech.com"
              className="w-full flex"
              style={{ justifyContent: "space-evenly" }}
              target="_blank"
            >
              <BiGlobe size={22} />
            </a>
            <a
              href="https://www.facebook.com/people/Hikal-Tech/61551876213893/"
              className="w-full flex"
              style={{ justifyContent: "space-evenly" }}
              target="_blank"
            >
              <BiLogoFacebook size={22} />
            </a>
            <a
              href="https://www.instagram.com/hikaltech/"
              className="w-full flex"
              style={{ justifyContent: "space-evenly" }}
              target="_blank"
            >
              <BiLogoInstagram size={22} />
            </a>
            <a
              href="https://www.linkedin.com/company/hikal"
              className="w-full flex"
              style={{ justifyContent: "space-evenly" }}
              target="_blank"
            >
              <BiLogoLinkedin size={22} />
            </a>
            <a
              href="https://www.tiktok.com/@hikaltech"
              className="w-full flex"
              style={{ justifyContent: "space-evenly" }}
              target="_blank"
            >
              <BiLogoTiktok size={22} />
            </a>
          </div>
        </div>
        {/* LINKS  */}
        <div
          className="col-span-1 flex items-center p-5"
          style={{
            justifyContent: "flex-start",
            alignContent: "space-between",
            flexDirection: "column",
            zIndex: "1",
          }}
        >
          <h6 className="font-bold text-white uppercase">{t("more")}</h6>
          <hr className="mt-1 mb-2 w-[30%]" />
          <a href="/services" className="py-1">
            {t("services")}
          </a>
          <a href="/faq" className="py-1">
            {t("faq")}
          </a>
          <a href="/careers-hirings" className="py-1">
            {t("careers_hirings")}
          </a>
          <p className="py-1">{t("blogs")}</p>
          <a href="/privacy-policy" className="py-1">
            {t("privacy_policy")}
          </a>
        </div>
        {/* DETAILS  */}
        <div
          className="col-span-1 flex items-center p-5"
          style={{
            justifyContent: "flex-start",
            alignContent: "space-between",
            flexDirection: "column",
            zIndex: "1",
          }}
        >
          <h6 className="font-bold text-white uppercase">{t("contact")}</h6>
          <hr className="mt-1 mb-2 w-[30%]" />
          <div
            className={`justify-content-start ${
              isLangRTL(i18n.language) ? "text-right" : "text-left"
            }`}
          >
            <div className="grid grid-cols-6 gap-3 py-1.5">
              <BiPhone size={22} />
              <a dir="ltr" href="tel:97142722249" className="col-span-5">
                +971 4 272 2249
              </a>
            </div>
            <div className="grid grid-cols-6 gap-3 py-1.5">
              <BiLogoGmail size={22} />
              <a href="mailto:info@hikaltech.ae" className="col-span-5">
                info@hikaltech.ae
              </a>
            </div>
            <div className="grid grid-cols-6 gap-3 py-1.5">
              <BiMapPin size={22} />
              <a
                href="https://www.google.com/maps/dir/25.2276763,55.2825905/25.224578,55.283364/@25.2293033,55.2458747,13.72z?entry=ttu"
                className="col-span-5"
              >
                {t("office_location")},
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* COPYRIGHT  */}
      <hr className="mt-1 mb-2 w-full" />
      <div className="text-center pt-2 pb-4" dir="ltr">
        ©{new Date().getFullYear()} Copyright
        <span className="px-2 font-bold uppercase" style={{ color: "white" }}>
          Arkan Technologies
        </span>
      </div>
    </div>
    // </div>
  );
};

export default Footer;
