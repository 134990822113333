import React, { useRef, useEffect, useCallback } from "react";
import * as THREE from "three";
import { Canvas, useFrame, extend, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { createNoise3D } from "simplex-noise";

// Helper function to generate random points on a sphere
function randomPointSphere(radius) {
  let theta = 2 * Math.PI * Math.random();
  let phi = Math.acos(2 * Math.random() - 1);
  let x = radius * Math.sin(phi) * Math.cos(theta);
  let y = radius * Math.sin(phi) * Math.sin(theta);
  let z = radius * Math.cos(phi);
  return [x, y, z];
}

const Scene = () => {
  const starsRef = useRef(null);
  const nucleusRef = useRef(null);
  const sphereBgRef = useRef(null);

  const { scene } = useThree();
  const noise = createNoise3D();
  const blobScale = 3;

  // Create objects (nucleus, sphere background, stars)
  const createObjects = useCallback(() => {
    const loader = new THREE.TextureLoader();
    const textureSphereBg = loader.load(
      "https://i.ibb.co/4gHcRZD/bg3-je3ddz.jpg"
    );
    const texturenucleus = loader.load(
      "https://i.ibb.co/hcN2qXk/star-nc8wkw.jpg"
    );
    const textureStar = loader.load("https://i.ibb.co/ZKsdYSz/p1-g3zb2a.png");

    // Nucleus
    texturenucleus.anisotropy = 16;
    const icosahedronGeometry = new THREE.IcosahedronGeometry(30, 10);
    const lambertMaterial = new THREE.MeshPhongMaterial({
      map: texturenucleus,
      transparent: true, // Enable transparency
      opacity: 0.6, // Set opacity to make the sphere more transparent
    });
    const nucleus = new THREE.Mesh(icosahedronGeometry, lambertMaterial);
    scene.add(nucleus);
    nucleusRef.current = nucleus;

    // Sphere Background
    textureSphereBg.anisotropy = 16;
    const geometrySphereBg = new THREE.SphereGeometry(150, 40, 40);
    const materialSphereBg = new THREE.MeshBasicMaterial({
      side: THREE.BackSide,
      map: textureSphereBg,
      transparent: true, // Enable transparency
      opacity: 0.3, // Set opacity to make the sphere more transparent
    });
    const sphereBg = new THREE.Mesh(geometrySphereBg, materialSphereBg);
    scene.add(sphereBg);
    sphereBgRef.current = sphereBg;

    // Moving Stars
    const starsGeometry = new THREE.BufferGeometry();
    const starPositions = [];
    const starVelocities = [];

    for (let i = 0; i < 500; i++) {
      const [x, y, z] = randomPointSphere(150);
      starPositions.push(x, y, z);
      starVelocities.push(THREE.MathUtils.randInt(50, 200));
    }

    starsGeometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(starPositions, 3)
    );

    const starsMaterial = new THREE.PointsMaterial({
      size: 5,
      color: "#ffffff",
      transparent: true,
      opacity: 0.2,
      map: textureStar,
      blending: THREE.AdditiveBlending,
      depthWrite: false,
    });

    const stars = new THREE.Points(starsGeometry, starsMaterial);
    stars.userData.velocities = starVelocities;
    scene.add(stars);
    starsRef.current = stars;
  }, [scene]);

  // Main animation loop
  useFrame(() => {
    if (starsRef.current && nucleusRef.current && sphereBgRef.current) {
      const stars = starsRef.current;
      const nucleus = nucleusRef.current;
      const sphereBg = sphereBgRef.current;

      // Animate stars
      const positions = stars.geometry.attributes.position.array;
      const velocities = stars.userData.velocities;

      for (let i = 0; i < positions.length; i += 3) {
        const vx = positions[i];
        const vy = positions[i + 1];
        const vz = positions[i + 2];

        positions[i] += (0 - vx) / velocities[i / 3];
        positions[i + 1] += (0 - vy) / velocities[i / 3];
        positions[i + 2] += (0 - vz) / velocities[i / 3];

        velocities[i / 3] -= 0.3;

        if (
          Math.abs(positions[i]) <= 5 &&
          Math.abs(positions[i + 1]) <= 5 &&
          Math.abs(positions[i + 2]) <= 5
        ) {
          const [x, y, z] = randomPointSphere(150);
          positions[i] = x;
          positions[i + 1] = y;
          positions[i + 2] = z;
          velocities[i / 3] = THREE.MathUtils.randInt(50, 300);
        }
      }
      stars.geometry.attributes.position.needsUpdate = true;

      // Nucleus Animation
      const time = Date.now();
      const vertices = nucleus.geometry.attributes.position.array;

      for (let i = 0; i < vertices.length; i += 3) {
        const vX = vertices[i];
        const vY = vertices[i + 1];
        const vZ = vertices[i + 2];

        const distance =
          nucleus.geometry.parameters.radius +
          noise(vX + time * 0.0005, vY + time * 0.0003, vZ + time * 0.0008) *
            blobScale;

        const length = Math.sqrt(vX * vX + vY * vY + vZ * vZ);

        vertices[i] = (vX / length) * distance;
        vertices[i + 1] = (vY / length) * distance;
        vertices[i + 2] = (vZ / length) * distance;
      }
      nucleus.geometry.attributes.position.needsUpdate = true;

      // Sphere Background Animation
      sphereBg.rotation.x += 0.002;
      sphereBg.rotation.y += 0.002;
      sphereBg.rotation.z += 0.002;
    }
  });

  // Initialize objects
  useEffect(() => {
    createObjects();
  }, [createObjects]);

  return null;
};

const BackgroundAnimation = () => {
  return (
    <Canvas>
      <OrbitControls
        autoRotate
        autoRotateSpeed={4}
        maxDistance={550}
        minDistance={300}
        enablePan={false}
      />
      <ambientLight intensity={1} />
      <directionalLight intensity={2} position={[0, 50, -20]} />
      <Scene />
    </Canvas>

    // <div
    //   id="canvas_container"
    //   style={{
    //     position: "absolute",
    //     // top: 0,
    //     // left: 0,
    //     width: "50vw",
    //     height: "50vh",
    //     zIndex: -1,
    //   }}
    // />
  );
};

export default BackgroundAnimation;
