import React from "react";
import { useStateContext } from "../../context/ContextProvider";

const index = ({ title, subtitle, isLangRTL }) => {
  const isMobile = window.innerWidth <= 800 ? true : false;

  console.log("ismobile : ", isMobile);

  return (
    <>
      <div className="relative text-center top-[15vh] mb-5 mt-3">
        <h1
          className={` ${
            isMobile ? "text-[4vh]" : "text-[22vh]"
          }  uppercase text-center font-light ${
            !isLangRTL && "tracking-[2vh]"
          } text-white opacity-10 absolute inset-0 flex justify-center items-center title-slide`}
        >
          {title}
        </h1>
        <p
          className={`relative uppercase ${
            isMobile ? "text-[3vw]" : "text-[1.2vw]"
          } z-10 text-white  ${!isLangRTL && "tracking-[0.8vw]"}`}
          style={{ color: "rgba(255,255,255,.5)" }}
        >
          {subtitle}
        </p>
      </div>
    </>
  );
};

export default index;
