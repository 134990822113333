import React, { useRef } from "react";

const Button = ({
  text, 
  color = "", 
  href = "#", 
  mode = "dark", 
  style, 
  type = "", 
  openInNewTab = false,
}) => {
  
  const linkProps = openInNewTab
    ? {
      target: '_blank',
      rel: 'noopener noreferrer',
    }
    : {};

  return (
    <div className="btn-container">
      <div style={style} className={`btn ${mode}`}>
        {type ? (
          <button type={type} style={{color: color || ""}}>
            {text}
          </button>
        ) : (
          <a style={{color: color || ""}} href={href} {...linkProps}>
            {text}
          </a>
        )}
        </div>
    </div>
  );
};

export default Button;
