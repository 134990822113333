import Ratings from "./Ratings";

const TestimonialCard = ({ name, rating, review, arabic }) => {
  return (
    <>
      <div className="h-[380px] w-full text-white">
        <div className="flex text-center py-2 flex-col justify-center items-center w-full">
          <h2
            className="font-bold text-xl mb-1"
            style={{
              // fontFamily: arabic ? "Noto Kufi Arabic" : "Raleway"
              fontFamily: arabic ? "Cairo" : "Raleway",
            }}
          >
            {name}
          </h2>
          <Ratings count={rating} />
          <p
            className="pt-8 text-lg w-[80%] text-center mx-auto"
            style={{
              // fontFamily: arabic ? "Noto Kufi Arabic" : "Raleway",
              fontFamily: arabic ? "Cairo" : "Raleway",
            }}
          >
            <blockquote className="styled-quote font-bold italic">
              {review}
            </blockquote>
          </p>
        </div>
      </div>
    </>
  );
};

export default TestimonialCard;
