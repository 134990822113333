import Hero from "../../components/hero/Hero";
import ContactUsForm from "../../components/contact/ContactUsForm";
import ParticleBackground from "../../components/ParticleBG";
import { useStateContext } from "../../context/ContextProvider";

const ContactPage = () => {
  const { t } = useStateContext();
  return (
    <>
      <ParticleBackground />
      <Hero page="Contact" title={t("contact")} subtitle={"Get In Touch"} />

      {/* <div className="w-full h-full pb-5"> */}
      <div
        id="contact-section"
        className="rounded-md w-[90%] md:w-[70%] mx-auto mt-60 flex flex-col items-center p-5 lg:p-10"
      >
        <ContactUsForm />
      </div>
      {/* </div> */}
    </>
  );
};

export default ContactPage;
