import Hero from "../../components/hero/Hero";
import ServiceHome from "../../components/services/ServicesHome";
import Testimonial from "../../components/home/Testimonial";
import PageTitle from "../../components/PageTitle";
import { useStateContext } from "../../context/ContextProvider";
import ParticleBackground from "../../components/ParticleBG/index";

const Services = () => {
  const { t } = useStateContext();
  return (
    <>
      <div>
        {/* <div className="particle-container"> */}
        <ParticleBackground />
        {/* </div> */}

        <Hero
          page="Services"
          title={t("services")}
          subtitle={"What we offer"}
        />

        <div id="services-section">
          <ServiceHome page={true} />
          <Testimonial />
        </div>
      </div>
    </>
  );
};

export default Services;
