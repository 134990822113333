import PrivacyPolicies from "../../components/plainText/PrivacyPolicies";

const PrivacyPolicy = () => {
  return (
    <>
        <PrivacyPolicies />
    </>
  );
};

export default PrivacyPolicy;
