import OurClients from "../../components/about/OurClients";
import Hero from "../../components/hero/Hero";
import AboutUs from "../../components/about/AboutUs";
import FAQ from "../../components/home/FAQ";
import Products from "../../components/home/Products";
import ServiceHome from "../../components/services/ServicesHome";

const Home = () => {
  return (
    <>
      <Hero page="Home" />
      {/* <ServiceHome />
      <Products />
      <AboutUs />
      <FAQ count={4} /> */}
    </>
  );
};

export default Home;
