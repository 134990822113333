import { useStateContext } from "../context/ContextProvider";

export default function useHeroPageContent() {
  const { t } = useStateContext();

  const HeroPageContent = [
    // HOME
    {
      page: "Home",
      home_subtitle: t("home_subheading"),
    },
    // {
    //   page: "Home",
    //   heading_white: t("home_heading_white"),
    //   heading_red: t("brand_name_full"),
    //   sub_heading: t("home_sub_heading"),
    //   content_qa: t("home_question"),
    //   content_ans: t("home_answer"),
    //   lottie:
    //     "https://lottie.host/c2fe32a2-9e85-423d-a442-6f7d5916d7a5/IHr40RyVQt.json",
    //   banner: t("home_banner"),
    //   button: t("explore_now"),
    // },
    // SERVICES
    {
      page: "Services",
      heading_white: t("services_heading_white"),
      heading_red: t("services_heading_red"),
      sub_heading: t("services_sub_heading"),
      content_qa: t("services_question"),
      content_ans: t("services_answer"),
      lottie:
        "https://lottie.host/1941c8d2-dba6-41c3-b996-ec39b8334b38/Lblb4uW4QA.json",
      banner: t("services_banner"),
      button: t("explore_now"),
    },
    // PRODUCTS
    {
      page: "Products",
      heading_white: t("products_heading_white"),
      heading_red: t("products_heading_red"),
      sub_heading: t("products_sub_heading"),
      content_qa: t("products_question"),
      content_ans: t("products_answer"),
      lottie:
        "https://lottie.host/229d8030-b82c-412b-b33a-83018872c4df/tyRhJUzNiy.json",
      banner: t("products_banner"),
      button: t("explore_now"),
    },
    // ABOUT
    {
      page: "About",
      heading_white: t("about_heading_white"),
      heading_red: t("about_heading_red"),
      sub_heading: t("about_sub_heading"),
      content_qa: t("about_question"),
      content_ans: t("about_answer"),
      lottie:
        "https://lottie.host/a16f50bd-490a-47c8-9c66-811736e49e61/tTYPfr615B.json",
      banner: t("about_banner"),
      button: t("read_more"),
    },
    // CONTACT
    {
      page: "Contact",
      heading_white: t("contact_heading_white"),
      heading_red: t("brand_name"),
      sub_heading: t("contact_sub_heading"),
      content_qa: t("contact_question"),
      content_ans: t("contact_answer"),
      lottie:
        "https://lottie.host/59bd0156-a968-4d8b-acba-86aa86deafa2/4VyA5xIHPA.json",
      button: t("contact_now"),
    },
  ];
  return HeroPageContent;
}
