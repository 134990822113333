import { motion } from "framer-motion";
import SectionHeading from "../sectionHeading/SectionHeading";
import ServicesCircle from "./ServicesCircle";
import { useStateContext } from "../../context/ContextProvider";
import PageTitle from "../PageTitle";

const ServicesHome = ({ page }) => {
  const { t } = useStateContext();

  return (
    <>
      <div className=" text-black py-5 mt-60 mb-4">
        <motion.div
          className="w-[80%] mx-auto"
          initial={{ transform: "translateY(200px)", opacity: 0 }}
          whileInView={{ transform: "translateY(0)", opacity: 1 }}
        >
          {/* <SectionHeading
            text={t("services")}
            caption={t("services_caption")}
          /> */}
          <p className="font-light text-white opacity-50 leading-6">
            {t("services_content")}
          </p>
          {page ? (
            // TODO : ADD ANIMATION CODEPEN AND DISPLAT CONTENT DIFFERENTLY
            <ServicesCircle />
          ) : (
            <ServicesCircle />
          )}
        </motion.div>
      </div>
    </>
  );
};

export default ServicesHome;
