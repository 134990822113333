import React, { useEffect, useRef } from "react";
import "../../index.css"; // Use an external CSS file for styling

const ParticleBackground = () => {
  const containerRef = useRef(null);

  const particleCount = 100;

  // Helper function to get a random value
  const randomValue = (min, max) => Math.random() * (max - min) + min;

  // Function to create a particle element
  const createParticle = () => {
    const particle = document.createElement("div");
    particle.classList.add("circle-container");

    const circle = document.createElement("div");
    circle.classList.add("circle");

    particle.appendChild(circle);

    // Randomly position the particle
    particle.style.left = `${randomValue(0, 100)}vw`;
    particle.style.top = `${randomValue(-10, 100)}vh`;

    // Random duration and delay for individual particle movement
    const duration = randomValue(10, 30); // Slower movement
    particle.style.animationDuration = `${duration}s`;

    return particle;
  };

  useEffect(() => {
    const container = containerRef.current;

    // Generate all particles
    for (let i = 0; i < particleCount; i++) {
      const particle = createParticle();
      container.appendChild(particle);
    }

    // Mouse movement effect
    const handleMouseMove = (event) => {
      const x = (event.clientX / window.innerWidth - 0.5) * 30; // Translate based on mouse position
      const y = (event.clientY / window.innerHeight - 0.5) * 30;
      container.style.transform = `translate(${x}px, ${y}px)`;
    };

    document.addEventListener("mousemove", handleMouseMove);

    // Cleanup mousemove event when component unmounts
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return <div className="container" ref={containerRef}></div>;
};

export default ParticleBackground;
