import React from "react";
import BgDots from "../bgDots";
import { BsCheckCircleFill } from "react-icons/bs";
import BgCircle from "../bgCircle";
import { useStateContext } from "../../context/ContextProvider";

const PrivacyPolicies = () => {
  const {
    t,
    i18n,
    isLangRTL
  } = useStateContext();

  return (
    <>
      <div className="hero flex relative py-10" style={{ minHeight: "100vh", width: "100vw" }}>
        <BgDots style={{
          left: !isLangRTL(i18n.language) && "5%",
          right: isLangRTL(i18n.language) && "5%",
          bottom: "20%",
          opacity: "0.5",
          position: "fixed",
        }} />
        <BgDots style={{
          right: !isLangRTL(i18n.language) && "5%",
          left: isLangRTL(i18n.language) && "5%",
          top: "20%",
          opacity: "0.7",
          position: "fixed",
        }} />
        <BgCircle
          width={170}
          height={170}
          style={{
            top: "10%",
            left: !isLangRTL(i18n.language) && "5%",
            right: isLangRTL(i18n.language) && "5%",
            zIndex: "0",
            position: "fixed",
            opacity: "0.7",
          }}
        />
        <BgCircle
          width={170}
          height={170}
          style={{
            top: "80%",
            left: isLangRTL(i18n.language) && "5%",
            right: !isLangRTL(i18n.language) && "5%",
            zIndex: "0",
            position: "fixed",
            opacity: "0.9",
          }}
        />
        <div className="w-[86%] mx-auto pt-10" style={{ zIndex: "1" }}>
          <div className="hero-padding py-5">
            <h1 className="text-white text-3xl font-semibold leading-tight uppercase my-3">
              {t("privacy_policy")}
            </h1>
            <div className="my-3">
              <div className="flex items-center text-gray-200 font-light">
                <BsCheckCircleFill size={16} color="red" />
                <p className={isLangRTL(i18n.language) ? "mr-2" : "ml-2"}>
                  {t("last_updated")}
                </p>
              </div>
              <div className="flex flex-col gap-12 items-center text-gray-200 font-light mt-5">
                <div className="flex flex-col gap-4 w-full">
                  <p className="leading-7">
                    {t("pp_001")}
                  </p>
                  <p className="leading-7">
                    {t("pp_002")}
                  </p>
                </div>
                <div className="flex flex-col gap-4 w-full">
                  <div className="font-bold uppercase text-[var(--primary-color)]">
                    {t("pp_100")}
                  </div>
                  <p className="leading-7">
                    {t("pp_101")}
                  </p>
                </div>

                <div className="flex flex-col gap-4 w-full">
                  <div className="font-bold uppercase text-[var(--primary-color)]">
                    {t("pp_200")}
                  </div>
                  <p className="leading-7">
                    {t("pp_201")}
                  </p>
                </div>
                <div className="flex flex-col gap-4 w-full">
                  <div className="font-bold uppercase text-[var(--primary-color)]">
                    {t("pp_300")}
                  </div>
                  <p className="leading-7">
                    {t("pp_301")}
                  </p>
                </div>

                <div className="flex flex-col gap-4 w-full">
                  <div className="font-bold uppercase text-[var(--primary-color)]">
                    {t("pp_400")}
                  </div>
                  <p className="leading-7">
                    {t("pp_401")}
                  </p>
                </div>

                <div className="flex flex-col gap-4 w-full">
                  <div className="font-bold uppercase text-[var(--primary-color)]">
                    {t("pp_500")}
                  </div>
                  <p className="leading-7">
                    {t("pp_501")}
                  </p>
                  <p className="leading-7">
                    {t("pp_502")}
                  </p>
                  <p className="leading-7">
                    {t("pp_503")}
                  </p>
                </div>

                <div className="flex flex-col gap-4 w-full">
                  <div className="font-bold uppercase text-[var(--primary-color)]">
                    {t("pp_600")}
                  </div>
                  <p className="leading-7">
                    {t("pp_601")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicies;
