import Azizi from "../assets/clients/azizi.png";
import Binghatti from "../assets/clients/binghatti.png";
import Empire from "../assets/clients/empire.png";
import Damac from "../assets/clients/damac.png";
import Danube from "../assets/clients/danube.png";
import Arada from "../assets/clients/arada.png";
import Pixium from "../assets/clients/pixium.png";
import Lychee from "../assets/clients/lycheegardens.png";

export const Clients = [
    {
        name: "Azizi",
        logo: Azizi,
    },
    {
        name: "Binghatti",
        logo: Binghatti,
    },
    {
        name: "Empire",
        logo: Empire,
    },
    {
        name: "Damac",
        logo: Damac,
    },
    {
        name: "Danube",
        logo: Danube,
    },
    {
        name: "Arada",
        logo: Arada,
    },
    {
        name: "Pixium",
        logo: Pixium,
    },
    {
        name: "Lychee Gardens",
        logo: Lychee,
    },
    // {
    //     name: "",
    //     logo: "",
    // },
    // {
    //     name: "",
    //     logo: "",
    // },
];