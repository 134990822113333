import { useFaqData } from "../../_data/FAQ";
import FAQBgHome from "../../assets/bg/faq-bg.png";
import FAQBg from "../../assets/bg/faq-bg.jpg";
import FAQuestion from "./FAQuestion";
import { useStateContext } from "../../context/ContextProvider";
import Button from "../button";

const FAQ = ({
  count
}) => {
  const {
    t,
    i18n,
    isLangRTL
  } = useStateContext();

  const FaqData = useFaqData(count);

  return (
    <div>
      <div className="w-full mx-auto bg-white">
        <div
          className="relative py-16"
          style={{
            backgroundImage: `url(${count ? FAQBgHome : FAQBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <div className="mt-[20px] faq-header mb-14 flex justify-center flex-col items-center">
            <h1 className="text-3xl font-bold mb-2">
              {t("faq_long")}
            </h1>
            {count && (
              <p className="text-sm font-light">
                {t("faq_content")}
              </p>
            )}
          </div>

          <div
            style={{ backdropFilter: "blur(3px)" }}
            className="w-[80%] mx-auto pb-5 grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8"
          >
            {FaqData?.map((item) => {
              return (
                <FAQuestion
                  question={item?.question}
                  answer={item?.answer}
                />
              );
            })}
          </div>

          {count && (
            <div className="w-full flex items-center justify-center pt-5">
              <Button href="/faq" text={t("read_more")} color={"black"} />
            </div>
          )}

          {count && (
            <>
              <div className={`absolute top-0 bottom-0 bg-white opacity-70 h-full w-[30px] ${isLangRTL(i18n.language) ? "right-0" : "left-0"
                }`}></div>
              <div className={`absolute top-0 bottom-0 bg-white opacity-70 h-full w-[30px] ${isLangRTL(i18n.language) ? "left-0" : "right-0"
                }`}></div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
