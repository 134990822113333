import {
  BsQuestion
} from "react-icons/bs";
import { useStateContext } from "../../context/ContextProvider";

const FAQuestion = ({ 
  question, 
  answer 
}) => {
  const {
    i18n,
    isLangRTL
  } = useStateContext();

  return (
    <div className="flex items-start">
      <div className={`p-1 mt-1 rounded bg-[#da1f2620] text-[#da1f26] ${
        isLangRTL(i18n.language) ? "ml-3" : "mr-3"
      }`}>
        <BsQuestion size={20} />
      </div>
      <div className="flex flex-col">
        <strong className="mb-1 text-sm">{question}</strong>
        <div className="text-sm">{answer}</div>
      </div>
    </div>
  );
};

export default FAQuestion;
