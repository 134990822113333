import { AiOutlineStar, AiFillStar } from "react-icons/ai";

const Ratings = ({ count }) => {
  return (
    <div className="flex items-center my-4">
      {[1, 2, 3, 4, 5].map((star) => {
        if (star <= count) {
          return (
            <AiFillStar
              style={{ marginRight: "4px", color: "#1e5799" }}
              size={18}
            />
          );
        } else {
          return (
            <AiOutlineStar
              style={{ marginRight: "4px", color: "#1e5799" }}
              size={18}
            />
          );
        }
      })}
    </div>
  );
};

export default Ratings;
