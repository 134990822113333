import { useWindowScroll } from "react-use";
import Logo from "../../assets/logo.png";
import { useEffect, useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useStateContext } from "../../context/ContextProvider";
import { FaPhoneAlt } from "react-icons/fa";
import { Menu } from "@mui/material";

const Nav = () => {
  const { t, i18n, langs, isLangRTL, setContactUsPopup } = useStateContext();

  let links = [
    {
      title: t("home"),
      links: [
        {
          name: t("home"),
          link: "/",
        },
      ],
    },
    {
      title: t("services"),
      links: [
        {
          name: t("services"),
          link: "/services",
        },
      ],
    },
    {
      title: t("products"),
      links: [
        {
          name: t("products"),
          link: "/products",
        },
      ],
    },
    {
      title: t("about"),
      links: [
        {
          name: t("about"),
          link: "/about",
        },
      ],
    },
    {
      title: t("contact"),
      links: [
        {
          name: t("contact"),
          link: "/contact",
        },
      ],
    },
  ];

  // FIXED HEADER
  const { y } = useWindowScroll();
  const location = useLocation();
  const [anchorElem, setAnchorElem] = useState("");
  const [open, setOpen] = useState(false);
  const [langDropdown, setLangDropdown] = useState(false);
  const [langFlag, setLangFlag] = useState("");

  console.log("y height: ", y);

  const handleClose = () => {
    setOpen(false);
    setAnchorElem(null);
    setLangDropdown(false);
  };

  const handleClick = (event, navBtn) => {
    setLangDropdown(true);
    setOpen(true);
    setAnchorElem(event.currentTarget);
  };

  useEffect(() => {
    const header = document.querySelector("header");
    if (header) {
      const scrolled = y > 0;
      header.classList.toggle("bg-black", scrolled);
      header.classList.toggle("bg-transparent", !scrolled);
    }
  }, [y]);

  useEffect(() => {
    const langDetails = langs.find((lang) => lang?.code === i18n.language);
    if (langDetails) {
      const { flag } = langDetails;
      setLangFlag(flag);
    }
  }, [i18n.language]);

  // SMALL SCREEN NAV
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navRef = useRef(null);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  const closeNav = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsNavOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", closeNav);
    return () => {
      document.removeEventListener("click", closeNav);
    };
  }, []);

  const handleLinkClick = () => {
    setIsNavOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", closeNav);
    return () => {
      document.removeEventListener("click", closeNav);
    };
  }, []);

  const changeBodyDirection = (newDirection) => {
    document.body.style.direction = newDirection;
    localStorage.setItem("direction", newDirection);
  };

  const changeFontFamily = (fontFamily) => {
    document.body.style.fontFamily = fontFamily;
    localStorage.setItem("font", fontFamily);
  };

  const homePage = location.pathname == "/" ? true : false;

  console.log("homepage : ", homePage);

  return (
    <>
      {/* HEADER */}
      <header
        className={`fixed ${
          homePage ? "bottom-14" : "top-0"
        }  w-full text-white transition-all duration-300 ${
          !homePage && y > 100 ? "bg-black" : "bg-transparent"
        } ${isLangRTL(i18n.language) ? "right-0" : "left-0"}`}
        style={{
          zIndex: 5,
          direction: "ltr",
        }}
      >
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          {/* CONTACT US FIXED BUTTON -----*/}
          {/* <button
            onClick={() => setContactUsPopup({ open: true })}
            style={{ transform: "translateY(-45%)" }}
            className={`text-white fixed top-[45%] text-sm font-bold bg-primary py-2 px-3 rounded shadow-md flex gap-3 justify-center items-center ${
              isLangRTL(i18n.language)
                ? "left-0 rounded-l-none"
                : "right-0 rounded-r-none"
            }`}
          >
            <FaPhoneAlt size={15} style={{ color: "white" }} />
            <span className="capitalize">{t("contact_us")}</span>
          </button> */}

          {/* LG SCREEN */}
          <nav
            className={`flex items-center ${
              homePage ? "justify-center" : "justify-between"
            } `}
          >
            {/* LOGO */}
            {homePage ? null : (
              <div className="flex-shrink-0 py-2">
                <a href="/" title="" className="flex gap-2 items-center">
                  <img
                    src={Logo}
                    width={90}
                    className="h-auto"
                    alt="Hikal Tech"
                  />
                </a>
              </div>
            )}
            {/* MOBILE SCREEN BUTTON  */}
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                toggleNav();
              }}
              className="inline-flex p-2 text-white transition-all duration-200 rounded-md lg:hidden"
            >
              {/* MENU OPEN: "hidden", MENU CLOSED: "block" */}
              <svg
                className="block w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 8h16M4 16h16"
                />
              </svg>
              {/* MENU OPEN: "block", MENU CLOSED: "hidden" */}
              <svg
                className="hidden w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {/* NAV LINKS */}
            <div
              className={`hidden lg:flex lg:items-center ${
                homePage ? "lg:gap-x-24" : "lg:gap-x-6"
              } ${homePage ? null : "lg:ml-auto"}`}
              style={{
                direction: isLangRTL(i18n.language) ? "rtl" : "ltr",
              }}
            >
              {links.map((section, index) =>
                section.links.map((link, linkIndex) => (
                  <NavLink
                    key={linkIndex}
                    to={link.link}
                    className={`inline-flex items-center gap-2 text-base font-medium transition-all duration-200 ${
                      homePage && "tracking-[0.2vw] uppercase"
                    } hover:text-[var(--primary-color)] hover:border-b-2 hover:border-[var(--primary-color)] ${
                      location.pathname === link.link
                        ? "text-[var(--primary-color)] font-semibold border-b-2 border-[var(--primary-color)]"
                        : "text-white"
                    } ${
                      !location.pathname ||
                      (index === 0 &&
                        linkIndex === 0 &&
                        location.pathname === "/")
                        ? "font-semibold text-[var(--primary-color)] border-b-2 border-[var(--primary-color)]"
                        : "text-white"
                    }`}
                  >
                    {t(link.name?.toLowerCase())}
                  </NavLink>
                ))
              )}

              <div
                className="mx-2 flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
                onClick={handleClick}
              >
                <img
                  className="rounded-sm h-5 w-7 border"
                  src={langFlag}
                  alt=""
                />
              </div>
            </div>
          </nav>

          {/* SCREEN SM TO LG  */}
          <nav
            ref={navRef}
            className={`py-4 mb-4 bg-black border border-[var(--primary-color)] rounded-xl shadow-md lg:hidden ${
              isNavOpen ? "block" : "hidden"
            }`}
            style={{
              direction: isLangRTL(i18n.language) ? "rtl" : "ltr",
            }}
          >
            <div className="flow-root">
              <div className="flex flex-col px-6 -my-2 space-y-1">
                {links.map((section, index) =>
                  section.links.map((link, linkIndex) => (
                    <Link
                      key={linkIndex}
                      to={link.link}
                      className={`inline-flex items-center gap-3 py-2 text-base font-medium text-white transition-all duration-200 hover:text-[var(--primary-color)] ${
                        location.pathname === link.link
                          ? "text-[var(--primary-color)] font-bold"
                          : ""
                      } ${
                        !location.pathname ||
                        (index === 0 &&
                          linkIndex === 0 &&
                          location.pathname === "/")
                          ? "text-[var(--primary-color)] font-bold"
                          : ""
                      }`}
                      onClick={handleLinkClick}
                    >
                      {link.name}
                    </Link>
                  ))
                )}
              </div>
            </div>
            <div
              className={`mt-5 flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg ${
                isLangRTL(i18n.language) ? "mr-6" : "ml-6"
              }`}
              onClick={handleClick}
            >
              <img
                className="rounded-sm h-5 w-7 border"
                src={langFlag}
                alt=""
              />
            </div>
          </nav>
        </div>
        <Menu
          className="hide-scrollbar navbar-menu-backdrop"
          hideBackdrop={false}
          onClick={handleClose}
          onMouseLeave={handleClose}
          anchorEl={anchorElem}
          open={open}
          PaperProps={{
            elevation: 0,
            sx: {
              mt: 2,
              py: 0.4,
              background: "rgb(0 0 0 / 0.7)",
              boxShadow: "0 0 4px rgb(238 238 238 / 0.7)",
              color: "white",
              minWidth: 100,
              maxWidth: 180,
              borderRadius: "10px",
            },
          }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          {langs?.map((lang) => (
            <button
              className={`cursor-pointer p-3 rounded-xl shadow-sm w-full`}
              onClick={(e) => {
                localStorage.setItem("language", lang.code);
                i18n.changeLanguage(lang.code);

                if (isLangRTL(lang.code)) {
                  changeBodyDirection("rtl");
                } else {
                  changeBodyDirection("ltr");
                }
                changeFontFamily(lang.font);
              }}
            >
              <div className="flex items-center justify-between px-3 gap-5">
                <div
                  style={{
                    fontFamily: lang?.font,
                  }}
                >
                  {lang.title}
                </div>
                <div>
                  <img
                    className="rounded-sm h-5 w-7 border"
                    src={lang.flag}
                    alt=""
                  />
                </div>
              </div>
            </button>
          ))}
        </Menu>
      </header>
    </>
  );
};

export default Nav;
