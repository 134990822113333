import React, { useState, useEffect, useRef } from "react";
import useServiceContent from "../../_data/ServiceContent";

const ServicesCircle = () => {
  const isMobile = window.innerWidth <= 800;

  const [activeTab, setActiveTab] = useState(2);
  const dotCircleRef = useRef(null);

  const ServiceContent = useServiceContent();

  const handleTabClick = (index) => {
    const dataTab = index;
    document
      .querySelectorAll(".itemDot")
      .forEach((item) => item?.classList.remove("active"));
    document.querySelector(`.itemDot${index}`)?.classList.add("active");
    document
      .querySelectorAll(".CirItem")
      .forEach((item) => item?.classList.remove("active"));
    document.querySelector(`.CirItem${index}`)?.classList.add("active");
    setActiveTab(dataTab);

    const container = document.querySelector(".dotCircle");
    if (container) {
      container.style.transform = `rotate(${360 - (dataTab - 1) * 36}deg)`;
      container.style.transition = "2s";
    }

    const fields = document.querySelectorAll(".itemDot");
    fields.forEach((item) => {
      item.style.transform = `rotate(${(dataTab - 1) * 36}deg)`;
      item.style.transition = "1s";
    });
  };

  useEffect(() => {
    const radius = isMobile ? 160 : 200;
    const fields = document.querySelectorAll(".itemDot");

    if (dotCircleRef.current) {
      const container = dotCircleRef.current;
      const width = container.clientWidth;
      const height = container.clientHeight;
      const step = (2 * Math.PI) / fields.length;

      fields.forEach((field, index) => {
        const angle = index * step;
        const x = Math.round(
          width / 2 + radius * Math.cos(angle) - field.clientWidth / 2
        );
        const y = Math.round(
          height / 2 + radius * Math.sin(angle) - field.clientHeight / 2
        );

        if (window.console) {
          console.log(field.textContent, x, y);
        }

        field.style.left = x + "px";
        field.style.top = y + "px";
      });

      const intervalId = setInterval(() => {
        const currentTab =
          activeTab === ServiceContent?.length - 1 ? 0 : activeTab + 1;

        fields.forEach((item) => item.classList.remove("active"));
        fields[currentTab].classList.add("active");

        document
          .querySelectorAll(".itemDot")
          .forEach((item) => item?.classList.remove("active"));
        document
          .querySelector(`.itemDot${currentTab}`)
          ?.classList.add("active");
        document
          .querySelectorAll(".CirItem")
          .forEach((item) => item?.classList.remove("active"));
        document
          .querySelector(`.CirItem${currentTab}`)
          ?.classList.add("active");

        container.style.transform = `rotate(${
          360 - currentTab * (360 / fields.length)
        }deg)`;
        container.style.transition = "2s";

        fields.forEach((item) => {
          item.style.transform = `rotate(${
            currentTab * (360 / fields.length)
          }deg)`;
          item.style.transition = "1s";
        });

        setActiveTab(currentTab);
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [activeTab]);

  return (
    <>
      <div dir="ltr" className="holderCircle">
        <div className="round"></div>
        <div ref={dotCircleRef} className="dotCircle">
          {ServiceContent?.map((services, index) => (
            <div
              key={index}
              className={`itemDot itemDot${index} ${
                activeTab === index
                  ? "active text-white"
                  : "text-[var(--primary-color)]"
              }`}
              data-tab={index}
              onClick={() => handleTabClick(index)}
            >
              <div className="forActive w-full h-full flex items-center justify-center">
                {services?.icon}
              </div>
            </div>
          ))}
        </div>
        <div className="contentCircle">
          {ServiceContent?.map((services, index) => (
            <div
              key={index}
              className={`CirItem title-box leading-6 ${
                activeTab === index ? "active" : ""
              } CirItem${index}`}
            >
              <h2 className="title font-semibold uppercase">
                <span>{services?.title}</span>
              </h2>
              <p className="text-white opacity-60">{services?.subtitle}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ServicesCircle;
