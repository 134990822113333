import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import TestimonialCard from "./TestimonialCard";
import SectionHeading from "../sectionHeading/SectionHeading";
import SmallCircle from "../bgCircle/SmallCircle";
import { useStateContext } from "../../context/ContextProvider";
import { Testimonials } from "../../_data/TestimonialsData";

const Testimonial = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const {t} = useStateContext();

  return (
    <div className="w-[100%] mx-auto relative">
      <div className="flex justify-center mt-3">
        <SectionHeading darkMode={true} caption={t("testimonial_caption")} text={t("testimonial")} />
      </div>
      <Slider className="relative w-[70%] md:w-[60%] mx-auto pt-12 pb-8" {...settings}>
        {Testimonials.map((data, index) => (
          <TestimonialCard 
            key={index}
            name={data?.name}
            rating={data?.rating}
            review={data?.review}
            arabic={data?.arabic} 
          />
        ))}
      </Slider>

      <SmallCircle size={10} style={{ left: 0, top: 250 }} />
      <SmallCircle size={12} style={{ right: 0, top: 330 }} />
      <SmallCircle size={18} style={{ right: 50, top: 100 }} />
      <SmallCircle size={18} style={{ left: 130, top: 30 }} />
      <SmallCircle size={12} style={{ left: 100, bottom: 0 }} />
      <SmallCircle size={20} style={{ right: 130, bottom: 0 }} />
    </div>
  );
};

export default Testimonial;
