import React, { useEffect } from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";
import { Clients } from "../../_data/ClientsData";

import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";

const OurClients = () => {
  const isMobile = window.innerWidth <= 800;

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: isMobile ? 2 : 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="w-full h-full py-5">
      <motion.div
        initial={{ transform: "translateY(200px)", opacity: 0 }}
        whileInView={{ transform: "translateY(0)", opacity: 1 }}
      >
        <div className="clients ">
          <div
            className="clients-container rounded-md p-4"
            style={{
              background: "linear-gradient(135deg, #1e5799 0%, #000000 100%)",
            }}
          >
            <Slider arrows={false} {...settings}>
              {Clients.map((client, index) => (
                <div
                  key={index}
                  className="custom-flex flex justify-center items-center"
                  style={{ display: "flex !important" }}
                >
                  <img
                    src={client.logo}
                    className="logogrid__img"
                    alt={client.name}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default OurClients;
