export const Testimonials = [
    {
        name: "أحمد البدوي",
        rating: 5,
        review: "اختيار هيكال تك كان من بين أفضل قراراتنا. حلولهم البرمجية لها تأثير كبير على كفاءتنا وإنتاجيتنا. الفريق في هيكال تك لا يمتلك فقط خبرة تقنية ولكنه متجاوب ويتعاون بشكل كبير. نتطلع إلى شراكة طويلة وناجحة.",
        arabic: true,
    },
    {
        name: "Youssef Ramadan Ashraf",
        rating: 5,
        review: "Hikal Tech's software solutions have streamlined our financial processes with unmatched cost-effectiveness and reliability. Working with them has been a truly positive experience.",
        arabic: false,
    },
    {
        name: "خالد كوري.",
        rating: 5,
        review: "هيكل تك يفهم بشكل عميق طبيعة التغيير في بيئة الأعمال. حلولهم البرمجية قدمت لنا المرونة والتوسع الذي نحتاجه للبقاء في المقدمة. فهم الفريق لصناعتنا والتزامهم بتقديم نتائج عالية الجودة يجعل هيكل تك شريكًا موثوقًا في استراتيجيتنا في تكنولوجيا المعلومات.",
        arabic: true,
    },
    {
        name: "Ahmed Amr",
        rating: 5,
        review: "We love our new website and branding by Hikal Tech! It brilliantly showcases our products. The user-friendly design exceeded our expectations. Excited for our continued partnership as we grow. Thanks for a fantastic digital presence!",
        arabic: false,
    },
];