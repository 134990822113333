import React from 'react';
import { motion } from "framer-motion";
import { Team } from '../../_data/TeamData';
import SectionHeading from '../sectionHeading/SectionHeading';
import TeamImage2 from "../../assets/team-2.png";
import TeamImage1 from "../../assets/team.png";
import { useStateContext } from '../../context/ContextProvider';
import {
    BsCheck
} from "react-icons/bs";

const WhyJoinUs = () => {
    const isMobile = window.innerWidth <= 800;
    const {t} = useStateContext();
    
    return (
        <div className={`w-full h-full ${isMobile ? "py-5" : "pb-5"}`}>
            <motion.div
                initial={{ transform: 'translateY(200px)', opacity: 0 }}
                whileInView={{ transform: 'translateY(0)', opacity: 1 }}
            >
                <div className="w-[80%] mx-auto">
                    <SectionHeading darkMode={true} text={t("why_join_us")} caption={t("benefits")} />
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-white py-5">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold flex items-center gap-2">
                                {t("why_title1")}
                                <BsCheck className="bg-[var(--primary-color)] rounded-full shadow-sm" size={20} />
                            </div>
                            <div className="leading-7 text-sm">
                                {t("why_subtitle1")}
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold flex items-center gap-2">
                                {t("why_title2")}
                                <BsCheck className="bg-[var(--primary-color)] rounded-full shadow-sm" size={20} />
                            </div>
                            <div className="leading-7 text-sm">
                                {t("why_subtitle2")}
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold flex items-center gap-2">
                                {t("why_title3")}
                                <BsCheck className="bg-[var(--primary-color)] rounded-full shadow-sm" size={20} />
                            </div>
                            <div className="leading-7 text-sm">
                                {t("why_subtitle3")}
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold flex items-center gap-2">
                                {t("why_title4")}
                                <BsCheck className="bg-[var(--primary-color)] rounded-full shadow-sm" size={20} />
                            </div>
                            <div className="leading-7 text-sm">
                                {t("why_subtitle4")}
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold flex items-center gap-2">
                                {t("why_title5")}
                                <BsCheck className="bg-[var(--primary-color)] rounded-full shadow-sm" size={20} />
                            </div>
                            <div className="leading-7 text-sm">
                                {t("why_subtitle5")}
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold flex items-center gap-2">
                                {t("why_title6")}
                                <BsCheck className="bg-[var(--primary-color)] rounded-full shadow-sm" size={20} />
                            </div>
                            <div className="leading-7 text-sm">
                                {t("why_subtitle6")}
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default WhyJoinUs;

