import React from "react";
import { motion, useScroll } from "framer-motion";

import BgDots from "../bgDots";
import { useStateContext } from "../../context/ContextProvider";
import Button from "../button";
import crmWebApp from "../../assets/crm-web-app.png";
import crmMobileApp from "../../assets/crm-mobile-app.png";
import attendanceApp from "../../assets/attendance-app.png";
import playStore from "../../assets/playstore-png.png";
import appStore from "../../assets/appstore-png.png";

const Products = () => {
  const { t, isLangRTL, i18n } = useStateContext();
  const { scrollYProgress } = useScroll();

  return (
    <div className="py-5 mt-72 mb-4">
      <div
        className="relative flex items-center"
        style={{
          //   backgroundImage: "linear-gradient(45deg, #f8171f, #000000)",
          backgroundImage: "linear-gradient(45deg,  #1e5799, #000000)",
          minHeight: "50vh",
        }}
      >
        <div className="w-[80%] mx-auto">
          <div className="flex product-item items-center justify-between">
            <div>
              <h1 className="text-white font-bold text-4xl mb-3">
                {t("product1")}
              </h1>
              <p className="font-light text-sm text-gray-50 mb-8">
                {t("product1_content")}
              </p>
              <Button
                mode="light"
                text={t("visit_website")}
                href={"https://hikalcrm.com"}
                openInNewTab={true}
              />
            </div>
            <div className="flex items-center justify-center">
              <img width={"70%"} src={crmWebApp} alt="" />
            </div>
          </div>
        </div>
        <BgDots
          style={{
            position: "absolute",
            top: "30px",
            right: !isLangRTL(i18n.language) && "30px",
            left: isLangRTL(i18n.language) && "30px",
          }}
          grid={{ x: 5, y: 3 }}
        />
      </div>

      <motion.div
        className="products-separator origin-[0%]"
        style={{ scaleX: scrollYProgress }}
      ></motion.div>

      <div
        className="relative flex items-center"
        style={{
          backgroundImage: "linear-gradient(45deg, #000000, #1e5799)",
          minHeight: "50vh",
        }}
      >
        <div className="w-[70%] mx-auto">
          <div className="flex product-item-2 items-center justify-between">
            <div className="flex items-center justify-center">
              <img width={"80%"} src={attendanceApp} alt="" />
            </div>
            <div className="flex mt-16 flex-col justify-center">
              <h1 className="text-white font-bold text-4xl mb-3">
                {t("product2")}
              </h1>
              <p className="font-light text-sm text-gray-50 mb-8">
                {t("product2_content")}
              </p>
              <div className="flex items-center relative -top-12">
                <img width={120} src={playStore} alt="" />
                {/* <img className="relative -left-7" width={200} src={appStore} alt=""/> */}
              </div>
            </div>
          </div>
        </div>
        <BgDots
          style={{
            position: "absolute",
            top: "30px",
            left: !isLangRTL(i18n.language) && "30px",
            right: isLangRTL(i18n.language) && "30px",
          }}
          grid={{ x: 5, y: 3 }}
        />
      </div>

      <motion.div
        className="products-separator origin-[100%]"
        style={{ scaleX: scrollYProgress }}
      ></motion.div>

      <div
        className="relative flex items-center"
        style={{
          backgroundImage: "linear-gradient(45deg, #1e5799, #000000)",
          minHeight: "50vh",
        }}
      >
        <div className="w-[70%] mx-auto">
          <div className="flex product-item-3 items-center justify-between">
            <div className="flex mt-16 flex-col justify-center">
              <h1 className="text-white font-bold text-4xl mb-3">
                {t("product3")}
              </h1>
              <p className="font-light text-sm text-gray-50 mb-8">
                {t("product3_content")}
              </p>
              <div className="flex thumb items-center relative -top-12">
                <img width={120} src={playStore} alt="" />
                <img
                  className="relative -left-7"
                  width={200}
                  src={appStore}
                  alt=""
                />
              </div>
            </div>
            <div className="flex items-center justify-center py-2">
              <img width={"70%"} src={crmMobileApp} alt="" />
            </div>
          </div>
        </div>
        <BgDots
          style={{
            position: "absolute",
            top: "30px",
            right: !isLangRTL(i18n.language) && "30px",
            left: isLangRTL(i18n.language) && "30px",
          }}
          grid={{ x: 5, y: 3 }}
        />
      </div>
    </div>
  );
};

export default Products;
