import { Route, Routes, useLocation } from "react-router";
import Home from "./pages/home";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useStateContext } from "./context/ContextProvider";
import { useEffect, useState } from "react";
import Nav from "./components/navbar/Nav";
import Footer from "./components/home/Footer";
import ScrollToTopButton from "./_elements/ScrollToTopButton";
import WhatsAppButton from "./_elements/WhatsAppButton";
import ContactUsFormPopup from "./components/contact/ContactUsFormPopup";
import ContactPage from "./pages/contact";
import AboutPage from "./pages/about";
import ProductsPage from "./pages/products";
import ServicesPage from "./pages/services";
import { ToastContainer } from "react-toastify";
import PrivacyPolicy from "./pages/privacy-policy";
import FAQs from "./pages/faq";
import CareersHirings from "./pages/careers-hirings";

const routes = [
  {
    path: "/home",
    element: <Home />,
    pageName: "Home",
  },
  {
    path: "/services",
    element: <ServicesPage />,
    pageName: "Services",
  },
  {
    path: "/products",
    element: <ProductsPage />,
    pageName: "Products",
  },
  {
    path: "/about",
    element: <AboutPage />,
    pageName: "About",
  },
  {
    path: "/contact",
    element: <ContactPage />,
    pageName: "Contact",
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    pageName: "Privacy Policy",
  },
  {
    path: "/faq",
    element: <FAQs />,
    pageName: "FAQs",
  },
  {
    path: "/careers-hirings",
    element: <CareersHirings />,
    pageName: "Careers/Hirings",
  },
];

function ScrollToTopOnPageChange() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { setAllRoutes, contactUsPopup, setContactUsPopup, i18n } =
    useStateContext();
  const location = useLocation();

  const [alreadyShown, setAlreadyShown] = useState(false);

  useEffect(() => {
    setAllRoutes(routes);
    i18n.changeLanguage(localStorage.getItem("language"));
    document.body.style.direction = localStorage.getItem("direction");
    document.body.style.fontFamily = localStorage.getItem("font");
    document.documentElement.style.setProperty(
      "--font-family",
      localStorage.getItem("font")
    );
  }, []);

  useScrollPosition(({ prevPos, currPos }) => {
    if (Math.abs(Math.ceil(currPos.y)) > 300) {
      if (!alreadyShown && location.pathname !== "/contact") {
        setContactUsPopup({ open: true });
        setAlreadyShown(true);
      }
    }
  });

  return (
    <>
      <ToastContainer />
      <ScrollToTopOnPageChange />
      <ScrollToTopButton />
      {/* <WhatsAppButton /> */}
      <div
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        <div className="flex" id="body-div" style={{ width: "100vw" }}>
          <div className={`w-[100%] overflow-x-hidden`}>
            <div className={`px-0`}>{/* <Nav /> */}</div>
            <div className="min-h-screen ">
              <Routes>
                <Route path="/" element={<Home />} />
                {routes.map((route, index) => {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                    />
                  );
                })}
              </Routes>
            </div>
          </div>
        </div>
        {location.pathname == "/" ? null : <Footer />}
        <div className="cursor">
          <div className="inner-circle"></div>
        </div>
      </div>

      {/* <ContactUsFormPopup
        modal={contactUsPopup}
        handleClose={() => setContactUsPopup(false)}
      /> */}

      {/* <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="*"
          element={
            <h1 className="text-red-500 text-center">
              Error 404 - Page Not Found
            </h1>
          }

          
        />
      </Routes> */}
      {/* <div className="cursor">
        <div className="inner-circle"></div>
      </div> */}
    </>
  );
}

export default App;
