import React from "react";
import BgDots from "../bgDots";
import { BsCheckCircleFill } from "react-icons/bs";
import BgCircle from "../bgCircle";
import Button from "../button";
import useHeroPageContent from "../../_data/HeroPageContent";
import Banner from "./Banner";
import { useStateContext } from "../../context/ContextProvider";
import Nav from "../navbar/Nav";

// import BackgroundAnimation from "./BackgroundAnimation";
import PageTitle from "../PageTitle";
import { useLocation } from "react-use";

import BackgroundAnimation from "./BackgroundAnimation";

const Hero = ({ page, minHeight = "100vh", title, subtitle }) => {
  const { i18n, isLangRTL, t, primaryColor } = useStateContext();
  const location = useLocation();

  console.log("location : ", location);

  const HeroPageContent = useHeroPageContent();

  const selectedPageData = HeroPageContent.find((item) => item.page === page);
  if (!selectedPageData) {
    return <div>404 Page Not Found</div>;
  }

  const isMobile = window.innerWidth <= 800 ? true : false;

  const {
    heading_white,
    heading_red,
    sub_heading,
    content_qa,
    content_ans,
    lottie,
    banner,
    button,
    home_subtitle,
  } = selectedPageData;

  return (
    // <div
    //   style={{
    //     minHeight: minHeight,
    //     width: "100vw",
    //     backgroundImage: `url(https://user-images.githubusercontent.com/26748614/96337246-f14d4580-1085-11eb-8793-a86d929e034d.jpg)`,
    //     backgroundSize: "cover",
    //     backdropFilter: "brightness(50%)",
    //   }}
    //   className="flex flex-col items-center justify-center overflow-hidden"
    // >
    //   {/* <BackgroundAnimation /> */}
    //   <div
    //     style={{
    //       position: "absolute",
    //       top: 0,
    //       left: 0,
    //       width: "100%",
    //       height: "100%",
    //       zIndex: -1, // Behind everything else
    //     }}
    //   >
    //     <BackgroundAnimation />
    //   </div>
    //   <h1
    //     className="text-center text-[5vw] tracking-[2.6vw] font-normal slide-up"
    //     style={{
    //       color: "hsla(192, 18%, 49%, 1)",
    //       // textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)",
    //       textShadow: "0px 6px 10px #3d5257",
    //       filter: "drop-shadow(0px 6px 10px #3d5257);",
    //     }}
    //   >
    //     {t("brand_name_full")}
    //   </h1>
    //   <p
    //     className="mr-3 text-center text-[1vw] tracking-[1.55vw] slide-up delay"
    //     style={{ color: "rgba(255, 255, 255, .5)" }}
    //   >
    //     {home_subtitle}
    //   </p>{" "}
    //   <Nav />
    // </div>

    <>
      {location.pathname == "/" ? (
        <div
          style={{
            // minHeight: minHeight,
            minHeight: "100vh",
            width: "100vw",
            backgroundImage: `url(https://user-images.githubusercontent.com/26748614/96337246-f14d4580-1085-11eb-8793-a86d929e034d.jpg)`,
            backgroundSize: "cover",
            backdropFilter: "brightness(10%)",
          }}
          className="flex flex-col items-center justify-center overflow-hidden"
        >
          <>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: -1, // Behind everything else
              }}
            >
              <BackgroundAnimation />
            </div>{" "}
            <h1
              className={`text-center   text-[5vw]
          ${!isLangRTL && "tracking-[2.6vw]"} font-normal slide-up uppercase`}
              style={{ color: "hsla(192, 18%, 49%, 1)" }}
            >
              {t("brand_name_full")}
            </h1>
            <p
              className={`mr-3 text-center ${
                isMobile ? "text-[2vw]" : "text-[1vw]"
              }  ${!isLangRTL && "tracking-[1.55vw]"} slide-up delay`}
              style={{ color: "rgba(255, 255, 255, .5)" }}
            >
              {home_subtitle}
            </p>{" "}
          </>
          <Nav />
        </div>
      ) : (
        <>
          <Nav />
          <PageTitle title={title} subtitle={subtitle} isLangRTL={isLangRTL} />
        </>
      )}
    </>

    // <div
    //   style={{
    //     minHeight: minHeight,
    //     width: "100vw",
    //     backgroundImage: `url(https://user-images.githubusercontent.com/26748614/96337246-f14d4580-1085-11eb-8793-a86d929e034d.jpg)`,
    //     backgroundSize: "cover",
    //     backdropFilter: "brightness(50%)",
    //   }}
    //   className="flex flex-col items-center justify-center overflow-hidden"
    // >
    //   <BackgroundAnimation />
    //   <h1
    //     className="text-center text-[5vw] tracking-[2.6vw] font-normal slide-up"
    //     style={{ color: "hsla(192, 18%, 49%, 1)" }}
    //   >
    //     {t("brand_name")}
    //   </h1>
    //   <p
    //     className="mr-3 text-center text-[1vw] tracking-[1.55vw] slide-up delay"
    //     style={{ color: "rgba(255, 255, 255, .5)" }}
    //   >
    //     {home_subtitle}
    //   </p>{" "}
    //   <Nav />
    // </div>
  );
};

export default Hero;
