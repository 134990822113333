const BgDots = ({ style, grid = { x: 5, y: 3 } }) => {
  const gridCells = Array(grid.x * grid.y).fill(null);

  return (
    <div className="bg-dots absolute grid grid-cols-3 gap-5" style={style}>
      {gridCells?.map(() => (
        <div></div>
      ))}
    </div>
  );
};

export default BgDots;
