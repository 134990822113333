import { useStateContext } from "../context/ContextProvider";

export const useFaqData = (count= null) => {
  const { t } = useStateContext();

  const faqData = [
    {
      question: t("faq1_qa"),
      answer: t("faq1_ans"),
    },
    {
      question: t("faq2_qa"),
      answer: t("faq2_ans"),
    },
    {
      question: t("faq3_qa"),
      answer: t("faq3_ans"),
    },
    {
      question: t("faq4_qa"),
      answer: t("faq4_ans"),
    },
    {
      question: t("faq5_qa"),
      answer: t("faq5_ans"),
    },
    {
      question: t("faq6_qa"),
      answer: t("faq6_ans"),
    },
    {
      question: t("faq7_qa"),
      answer: t("faq7_ans"),
    },
    {
      question: t("faq8_qa"),
      answer: t("faq8_ans"),
    },
    {
      question: t("faq9_qa"),
      answer: t("faq9_ans"),
    },
    {
      question: t("faq10_qa"),
      answer: t("faq10_ans"),
    },
    {
      question: t("faq11_qa"),
      answer: t("faq11_ans"),
    },
    {
      question: t("faq12_qa"),
      answer: t("faq12_ans"),
    },
  ];

  if (count === null) {
    return faqData;
  }
  else {
    const shuffledFaqData = faqData.sort(() => Math.random() - 0.5);
    return shuffledFaqData.slice(0, count);
  }
};
