const SmallCircle = ({ size, style }) => {
  return (
    <div
      style={{
        ...style,
        position: "absolute",
        animation: "fill 5s ease-in-out infinite",
        width: size,
        height: size,
      }}
      className={`border border-[var(--primary-color)] rounded-full`}
    ></div>
  );
};

export default SmallCircle;
