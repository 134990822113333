import {
    BsWindowStack,
    BsTablet,
    BsCodeSlash,
    BsMegaphone,
    BsBalloonHeart,
    BsSearchHeart
} from 'react-icons/bs';
import { useStateContext } from '../context/ContextProvider';

export default function useServiceContent(){
    const {t} = useStateContext();
    const ServiceContent = [
    {
        id: "service1",
        icon: <BsWindowStack size={30} />,
        title: t("service1_title"),
        subtitle: t("service1_subtitle"),
        content: t("service1_content"),
    },
    {
        id: "service2",
        icon: <BsTablet size={30} />,
        title: t("service2_title"),
        subtitle: t("service2_subtitle"),
        content: t("service2_content"),
    },
    {
        id: "service3",
        icon: <BsCodeSlash size={30} />,
        title: t("service3_title"),
        subtitle: t("service3_subtitle"),
        content: t("service3_content"),
    },
    {
        id: "service4",
        icon: <BsMegaphone size={30} />,
        title: t("service4_title"),
        subtitle: t("service4_subtitle"),
        content: t("service4_content"),
    },
    {
        id: "service5",
        icon: <BsBalloonHeart size={30} />,
        title: t("service5_title"),
        subtitle: t("service5_subtitle"),
        content: t("service5_content"),
    },
    {
        id: "service6",
        icon: <BsSearchHeart size={30} />,
        title: t("service6_title"),
        subtitle: t("service6_subtitle"),
        content: t("service6_content"),
    }
];

return ServiceContent;
}